import './App.css';
import React from 'react';
import Root from './Root';


const App = () => {
  return (
    <Root />
  );
};

export default App;
